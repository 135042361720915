const Privacy = () => {
  return (
    <header className="App-header">
      <p>Privacy Policy</p>
      <p>This site respects your privacy by having zero functionality.</p>
    </header>
  );
};

export default Privacy;
